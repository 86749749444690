import React, { Component } from 'react';
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import './DruidScanner.scss';
import * as storeActions from "../../store/actions";
import ProgressBar from "../UI/ProgressBar/ProgressBar";
import {DialogTemplate} from "../UI/Dialog/DialogTemplate";
import {withRouter} from "react-router-dom";
import icon from "../../assets/icons/safety.png";
import ScanDocument from "./ScanDocument/ScanDocument";
import error from "eslint-plugin-react/lib/util/error";

// export const DRUID_CONFIDENCE = window.config.druid_scan_threshold ? window.config.druid_scan_threshold : 0.65;
// export const DRUID_FACE = 0.65;

const FIRST_INTERVAL_TIME = 3000; // ms
const FIRST_INTERVAL_INCREMENT = 5; // ms
const END_INTERVAL_INCREMENT = 1; // ms
const FIRST_INTERVAL_END = 75; // %
const PROGRESS_TIMEOUT = 15000; // ms
const FEEDBACK_TIME = 4000; // ms

class DruidScanner extends Component
{

    constructor(props) {
        super(props);
        this.inputOpenFileRef = React.createRef();
        this.files = [];
        this.defaultPopupMessage = props.t("Checking document...");
        this.state.popupMessage = this.defaultPopupMessage;
        this.getCameraPermissions();

    }

    state = {
        progress: 0,
        popupMessage: '',
        open: false,
        timeout: false,
        sizeError: false,
    };


    triggerLoading() {
        let _self = this;
        this.firstInterval = setInterval(() => {
            let newProgress = _self.state.progress + FIRST_INTERVAL_INCREMENT;
            if(newProgress > FIRST_INTERVAL_END) {
                newProgress = FIRST_INTERVAL_END;
            }
            _self.setState({
                ..._self.state,
                progress: newProgress
            })
            if (newProgress === FIRST_INTERVAL_END) {
                _self.endInterval = setInterval(() => {
                    let newProgress = _self.state.progress + END_INTERVAL_INCREMENT;
                    if (newProgress > 100) {
                        newProgress=100;
                    }
                    _self.setState({
                        ..._self.state,
                        progress: newProgress,
                    })
                    if (newProgress >= 100) {
                        _self.setState({
                            ..._self.state,
                            timeout: true
                        })
                        clearInterval(_self.endInterval);
                    }
                },(PROGRESS_TIMEOUT-FIRST_INTERVAL_TIME)/(100-FIRST_INTERVAL_END)/END_INTERVAL_INCREMENT);
                clearInterval(_self.firstInterval);
            }
        },FIRST_INTERVAL_TIME/(FIRST_INTERVAL_END/FIRST_INTERVAL_INCREMENT))
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.open === false && prevState.open !== this.state.open) {
            let validations = this.props?.player?.info?.validations;

            // REDIRECT
            if (!validations || validations.error_reading) {
                return this.props.history.push('/scan-response/reading-error/' + this.props.type)
            }
            if (validations.error_scanning) {
                if (validations.ocr.length === 1) {
                    if (typeof validations.ocr.ExpireDate !== 'undefined') {
                        return this.props.history.push('/scan-response/expired-document/' + this.props.type);
                    }
                }
                return this.props.history.push('/scan-response/document-invalid/' + this.props.type);
            }

            if (validations.age && validations.age < 18) {
                return this.props.history.push('/scan-response/child-document/' + this.props.type);
            }
            return this.props.history.push('/scan-response/'+ (scanOk(this.props.player) ? 'success' : 'locked') + '/' + this.props.type)
        }
        if (this.props.druid !== prevProps.druid && prevProps.scanInProgress !== this.props.scanInProgress) {
            this.setState({
                   timeout: false,
                   progress: 100,
            });

        }
        if (prevProps.scanInProgress !== this.props.scanInProgress) {
            if (this.props.scanInProgress) {
                this.setState({
                    ...this.state,
                    open: true,
                    timeout:false,
                    popupMessage: this.defaultPopupMessage,
                });
                this.triggerLoading();
            } else {
                this.setState({
                    ...this.state,
                    progress: 100
                });
                clearInterval(this.endInterval);
                clearInterval(this.firstInterval);
            }
        }
        if (prevState.progress !== this.state.progress) {
            if (this.state.progress === 100) {

                this.setState({
                    ...this.state,
                    popupMessage: this.getStatusMessage(true)
                })
                setTimeout(() => {
                    this.setState({
                        ...this.state,
                        open: false,
                        progress: 0
                    })
                },FEEDBACK_TIME)
            }
        }
    }

    dragHandler(event) {
        event.preventDefault();
        event.stopPropagation();

        return false;
    }
    hasFiles(event) {
        let hasFiles = false;

        if (null === event.dataTransfer) {
            return hasFiles;
        }

        const types = event.dataTransfer.types;
        for (const keyOrIndex in types) {
            if (types[keyOrIndex] === 'Files') {
                hasFiles = true;
            }
        }

        return hasFiles;
    }
    dropHandler(event) {
        event.preventDefault();
        event.stopPropagation();

        event.persist();

        if (false === this.hasFiles(event)) {
            this.setState({
                ...this.state,
                open: false,
                progress: 0,
                sizeError: false
            })
            return false;
        }

        if (!event.dataTransfer) {
            return false;
        }

        let files = event.dataTransfer.files;

        this.upload(files);
        return false;
    }

    showOpenFileDlg() {
        this.inputOpenFileRef.current.files = null;
        this.inputOpenFileRef.current.value = '';
        this.inputOpenFileRef.current.click();
    }

    inputFilesHandler(event) {

        let files = this.inputOpenFileRef.current.files;
        if (!files) {
            this.setState({
                ...this.state,
                open: false,
                progress: 0,
                sizeError: false
            })
            return false;
        }

        this.upload(files);
    }

    upload(files,opt) {
        console.log('uploading',files,opt);
        if(files instanceof File) {
            files = [files];
        }
        let error = false;
        this.files = [];
        if (this.props.documentsMaxFileSize) {
            let size = this.props.documentsMaxFileSize.replace('Mb','');
            Object.keys(files).forEach((attr) => {
                if(files[attr].size/(1048576) < size) {
                    this.files.push(files[attr]);
                } else {
                    error  = true;
                    this.setState({
                        ...this.state,
                        sizeError: true
                    })
                }
            });
        }

        if (!error) {
            this.props.scanDocument(this.files,this.props.type, opt);
        }
    }

    getStatusMessage(popup) {
        let message, className;
        if (!this.state.sizeError) {
            if (!this.props.druid) {
                return null;
            }
            if (!this.state.timeout) {
                if(!(this.props.druid.Status || this.props.druid.title)) {
                    return null
                }
            }

            message = popup ? this.props.t("ERROR") : this.props.t("The document is unclear. Try with another picture");
            className = 'error';
            if(scanOk(this.props.player)) {
                message = popup ? this.props.t("SUCCESS") + "!" : this.props.t("The document was scanned successfully");
                className = 'success';
            }
        } else {
            let size = this.props.documentsMaxFileSize;
            message = this.props.t(`The maximum file size is ${size}`);
            className = 'error';
        }

        return <div className={className}>{message}</div>;
    }

    getLoadingScreen() {
        return (
            <DialogTemplate
                open={this.state.open}
                className={`druid-modal`}
                title={this.props.t('Reading document')}
                icon={false}
            >
                <div className="druid-modal-message">
                    {this.state.popupMessage}
                    <ProgressBar value={this.state.progress} />
                </div>
            </DialogTemplate>
        )
    }

    getPicture() {
        navigator.camera.getPicture(this.pictureSuccess.bind(this),this.pictureError.bind(this),{
            destinationType: navigator.camera.DestinationType.DATA_URL
        });
    }
    pictureError(a,b,c) {
        console.error(a,b,c);
    }
    pictureSuccess(data) {
        // let file = this.createFile(filePath);
        let binary = atob(data);

        let array = [];
        for (let i = 0; i < binary.length; i++) {
            array.push(binary.charCodeAt(i));
        }
        let blob = new Blob([new Uint8Array(array)], { type: 'image/jpeg' });

        let file = new File([blob], "image.jpg", { type: 'image/jpeg', lastModified: new Date() });
        if (window.cordova && window.cordova.plugins && window.cordova.platformId === "android") {

            this.setState({
                ...this.state,
                open: true,
            })
            window.cordova.plugins.NewtonVisionScanner.scanIDCard(data, (result) => {
                let text = JSON.parse(result);
                console.log(text)   ;
                window.cordova.plugins.NewtonVisionScanner.processMRZ(text.recognizedText, (mrz) => {
                    console.log(mrz);
                    this.upload(file,mrz);
                },(error) => {
                    console.error(error,'e2');
                });
            },(error)=> {
                console.error(error,'e');
            })
        } else {
            this.upload(file);
        }

    }

    getCameraPermissions() {

        if (window.cordova && window.cordova.plugins && window.cordova.platformId === "android")
        {

            let permissions = window.cordova.plugins.permissions;
            if (permissions) {
                try {

                    let self = this;
                    permissions.hasPermission(permissions.CAMERA, function (status) {
                        console.log('checking camera permissions', permissions.CAMERA,status )
                        if (status.hasPermission) {
                            //CAMERA permission already granted
                            console.log("Permission granted!");
                        }
                        else {
                            console.log('need to request camera permission')
                            // need to request camera permission
                            permissions.requestPermission(permissions.CAMERA, success, error);

                            function error() {
                                console.log("Permission denied!");
                            }

                            function success(status) {
                                if (status.hasPermission) {
                                    console.log("Permission accepted!");
                                } else {
                                    console.log('Permission denied...', status);
                                }
                            }
                        }
                    },function (error) {console.log(error)});
                } catch (err) {
                    console.log(err);
                }
            }
        }
    }

    render() {

        return (
            <div className={'druid-scanner-wrapper' }>
                {<div className={'druid-scanner'}
                     onDragOver={this.dragHandler.bind(this)}
                     onDragLeave={this.dragHandler.bind(this)}
                     onDrop={this.dropHandler.bind(this)}
                     onClick={this.showOpenFileDlg.bind(this)}
                >

                    <div className="content">
                        <div className="text">
                            <div className="title">
                                {this.props.label ? this.props.label : this.props.t("Scan")}
                            </div>
                        </div>
                        <div className="upload-image-wrapper">
                            <div className="upload-image">
                                <img
                                    src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNS44NzQiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyNS44NzQgMjEiPg0KICA8ZyBpZD0icGhvdG8tY2FtZXJhIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwIC00Ni4xNSkiPg0KICAgIDxnIGlkPSJHcm91cF83OTQiIGRhdGEtbmFtZT0iR3JvdXAgNzk0IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwIDQ2LjE1KSI+DQogICAgICA8cGF0aCBpZD0iUGF0aF85MTEiIGRhdGEtbmFtZT0iUGF0aCA5MTEiIGQ9Ik0wLDUyLjU3NlY2My45OTJBMy4xNTksMy4xNTksMCwwLDAsMy4xNTgsNjcuMTVIMjIuNzE2YTMuMTU5LDMuMTU5LDAsMCwwLDMuMTU4LTMuMTU4VjUyLjU3NmEzLjAwNiwzLjAwNiwwLDAsMC0zLTNoLTQuMmwtLjEtLjQzOGEzLjgzOCwzLjgzOCwwLDAsMC0zLjc2LTIuOTgzSDExLjA2MkEzLjg0NywzLjg0NywwLDAsMCw3LjMsNDkuMTMzbC0uMS40MzhIM0EzLjAxLDMuMDEsMCwwLDAsMCw1Mi41NzZabTcuNzItMS43MTFhLjY0NC42NDQsMCwwLDAsLjYyOC0uNWwuMjE2LS45NGEyLjU1NCwyLjU1NCwwLDAsMSwyLjUtMS45OGgzLjc0NGEyLjU1NCwyLjU1NCwwLDAsMSwyLjUsMS45OGwuMjE2Ljk0YS42NDkuNjQ5LDAsMCwwLC42MjguNWg0LjcxNWExLjcxLDEuNzEsMCwwLDEsMS43MTEsMS43MTFWNjMuOTkyYTEuODY0LDEuODY0LDAsMCwxLTEuODY0LDEuODY0SDMuMTU4YTEuODY0LDEuODY0LDAsMCwxLTEuODY0LTEuODY0VjUyLjU3NkExLjcxLDEuNzEsMCwwLDEsMyw1MC44NjVaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwIC00Ni4xNSkiIGZpbGw9IiNmZmYiLz4NCiAgICAgIDxjaXJjbGUgaWQ9IkVsbGlwc2VfMTQ1IiBkYXRhLW5hbWU9IkVsbGlwc2UgMTQ1IiBjeD0iMC44NjYiIGN5PSIwLjg2NiIgcj0iMC44NjYiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDMuNTExIDYuNjExKSIgZmlsbD0iI2ZmZiIvPg0KICAgICAgPHBhdGggaWQ9IlBhdGhfOTEyIiBkYXRhLW5hbWU9IlBhdGggOTEyIiBkPSJNMTQ3LjUzNCwxODYuMDE3YTUuNDMzLDUuNDMzLDAsMSwwLTUuNDM0LTUuNDMzQTUuNDQyLDUuNDQyLDAsMCwwLDE0Ny41MzQsMTg2LjAxN1ptMC05LjU3M2E0LjE0LDQuMTQsMCwxLDEtNC4xNCw0LjE0QTQuMTQ3LDQuMTQ3LDAsMCwxLDE0Ny41MzQsMTc2LjQ0NFoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xMzQuNTk3IC0xNjguMzM4KSIgZmlsbD0iI2ZmZiIvPg0KICAgIDwvZz4NCiAgPC9nPg0KPC9zdmc+DQo="
                                    alt="camera" />
                            </div>
                        </div>
                    </div>
                    {!window.cordova && <input type="file" disabled={this.state.open} capture="camera" accept="image/*" style={{display: "none"}} ref={this.inputOpenFileRef}  onChange={this.inputFilesHandler.bind(this)} />}
                    {window.cordova && <div  ref={this.inputOpenFileRef}  onClick={this.getPicture.bind(this)}/>}
                    {this.getLoadingScreen()}
                </div>}
                <div className="safety-badge">
                    <div className="icon"><img src={icon} alt={this.props.t("100% safe")}/></div>
                    <div className="text">{this.props.t("100% safe")}</div>
                </div>
                {/*{*/}
                {/*     <>{this.getStatusMessage()}</>*/}
                {/*}*/}
                {/*{<ScanDocument open={this.state.open}/>}*/}
            </div>
        );
    }
}

export const scanOk = (player) => {
    let ok = true;

    if(typeof player.self_exclusion !== "undefined" && player.self_exclusion.status) {
        ok = false;
    } else  if(player.blacklist && player.blacklist.status){
        ok = false;
    }
    return ok;
}

const mapStateToProps = state => {

    return {
        player: state.player,
        scanInProgress: state.druid.inProgress,
        documentsMaxFileSize: state.druid.documentsMaxFileSize,
        druid: state.druid.data
    }
};
const mapDispatchToProps = dispatch => {
    return {
        scanDocument: (file,type,opt) => dispatch(storeActions.scanDocument(file,type,opt)),

    }

}
export default withRouter(withTranslation()(connect(mapStateToProps,mapDispatchToProps)(DruidScanner)));
